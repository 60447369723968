@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Palette as HSL values (Don’t include the color space function or opacity modifiers won’t work) */
    --grey-600: 347 3% 12%;
    --grey-500: 0 3% 29%;
    --grey-400: 9 3% 45%;
    --grey-300: 17 3% 62%;
    --grey-200: 26 3% 79%;
    --grey-150: 26 3% 87%;
    --grey-100: 43 3% 95%;

    /* Primary */
    --primary-100: 332 100% 48%;
    --primary-80: 332 100% 68.8%;
    --primary-60: 329 82% 76.1%;
    --primary-40: 332 100% 87.5%;
    --primary-20: 332 100% 93.7%;
    --primary-10: 356 50% 95%;

    /* Base */
    --base-background: var(--grey-100);
    --base-foreground: var(--grey-600);
    --base-border: var(--grey-150);
    --base-input: var(--grey-150);
    --base-ring: var(--primary-100);

    /* Secondary */
    --secondary-black: 243 47.8% 9%;
    --secondary-dkgrey: 245 5.6% 45.3%;
    --secondary-mdgrey: 240 4.9% 63.7%;
    --secondary-ltgrey: 240 4.3% 91%;
    --secondary-tan: 40 28.6% 89.8%;
    --secondary-beige: 45 30.8% 94.9%;
    --secondary-ivory: 45 22.2% 96.5%;
    --secondary-white: 0 0% 100%;
    /* Muted */
    --muted-orange: 20 91.7% 85.9%;
    --muted-green: 106 83.3% 92.9%;
    --muted-sky: 188 92.3% 94.9%;
    --muted-indigo: 222 86.7% 94.1%;
    --muted-purple: 272 78.9% 92.5%;
    --muted-background: 45 10.8% 80.9%;

    /* Accent */
    --accent-teal: 186 100% 26.9%;
    --accent-blue: 209 70.4% 54.9%;
    --accent-gold: 39 68% 62%;
    --accent-mint: 160 90% 84.3%;

    --accent-pine: 146 20% 22.5%;

    /* Default background color of <body />...etc */
    --background: var(--base-background);
    --foreground: var(--base-foreground);

    /* Background color for <Card /> */
    --card: var(--base-background);
    --card-foreground: var(--base-foreground);

    /* Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover /> */
    --popover: var(--base-background);
    --popover-foreground: var(--base-foreground);

    /* Primary colors for <Button /> */
    --primary: var(--primary-100);
    --primary-foreground: var(--secondary-white);

    /* Secondary colors for <Button /> */
    --secondary: var(--secondary-black);
    --secondary-foreground: var(--secondary-white);

    /* Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch /> */
    --muted: var(--muted-background);
    --muted-foreground: var(--secondary-mdgrey);

    /* Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc */
    --accent: var(--secondary-white);
    --accent-foreground: var(--base-foreground);

    /* Used for destructive actions such as <Button variant="destructive"> */
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    /* Default border color */
    --border: var(--base-border);
    /* Border color for inputs such as <Input />, <Select />, <Textarea /> */
    --input: var(--base-input);
    /* Used for focus ring */
    --ring: var(--base-ring);

    /* Border radius for card, input and buttons */
    --radius: 1rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  html {
    font-family: var(--font-family-sans);
  }

  body {
    @apply bg-background text-foreground text-[16px] md:text-[18px];
    background: hsl(var(--base-background));
    color: hsl(var(--base-foreground));
  }

  hr {
    @apply mt-8 mb-8 col-span-full;
  }

  .container,
  .grid-container {
    @apply !max-w-[1728px];
  }

  .grid-container {
    @apply grid grid-cols-6 lg:grid-cols-12 gap-4 md:gap-6 container;
  }

  h1,
  h2,
  h3 {
    @apply font-600;
  }
}
